if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = function (callback, thisArg) {
        thisArg = thisArg || window;
        for (var i = 0; i < this.length; i++) {
            callback.call(thisArg, this[i], i, this);
        }
    };
}

class Nav {
    constructor(trigger, container, closeTrigger, headerContainer, controlsContainer)
    {
        this.trigger = trigger;
        this.container = container;
        this.closeTrigger = closeTrigger;
        this.headerContainer = headerContainer;
        this.controlsContainer = controlsContainer;
        this.isOpen = false;

        this.prevScrollY = window.scrollY;
    }

    open()
    {
        this.trigger.classList.add('active');
        this.container.classList.add('active');
        if (typeof this.onOpen === "function") this.onOpen();
        this.isOpen = true;
    }

    close()
    {
        this.trigger.classList.remove('active');
        this.container.classList.remove('active');
        if (typeof this.onClose === "function") this.onClose();
        this.isOpen = false;
    }

    toggle()
    {
        if (this.isOpen) this.close();
        else this.open()
    }

    onWindowScroll()
    {
        if (window.scrollY > 80 && window.scrollY > this.prevScrollY && !this.headerContainer.classList.contains('scroll-hide')) {
            this.headerContainer.classList.add('scroll-hide');
        } else if (window.scrollY > 80 && window.scrollY < this.prevScrollY && this.headerContainer.classList.contains('scroll-hide')) {
            this.headerContainer.classList.remove('scroll-hide');
            this.headerContainer.classList.add('scroll-bg');
        } else if (window.scrollY < 80 && this.headerContainer.classList.contains('scroll-bg')) {
            this.headerContainer.classList.remove('scroll-bg');
        }

        this.prevScrollY = window.scrollY;
    }

    init()
    {
        this.trigger.addEventListener('click', () => { this.toggle() });
        this.closeTrigger.forEach((el) => {
            el.addEventListener('click', () => { this.close() });
        });
        window.addEventListener('resize', () => { this.close() });
        window.addEventListener('scroll', () => { this.onWindowScroll() });

        return this
    }
}


class ScrollPart {

    constructor(selector, duration, startAt=0)
    {
        this.selector = selector;
        this.elem = document.querySelector(this.selector);
        this.duration = duration;
        this.startAt = startAt;
        this.getFrom = () => { return {} };
        this.getTo = () => { return {} };
    }


    pauseAnimation() {
        this.tween.pause();
    }
    
    resumeAnimation() {
        this.tween.resume();
    }

    init()
    {
        this.tween = new TweenMax.fromTo(this.elem, this.duration, this.getFrom(), this.getTo());
    }


}


class ScrollScene {

    constructor(controller, triggerElement, triggerHook)
    {
        this.controller = controller;

        this.triggerElement = triggerElement;
        this.triggerHook = triggerHook;

        this.scene = new ScrollMagic.Scene();
        this.timeLine = new TimelineMax();

        this.timeLineItems = [];

        this.getDuration = () => { return 0 };
        this.getOffset = () => { return 0 };

        this.isResizing = false;
    }

    onResize()
    {
        // this.scene.duration(this.getDuration());
        this.timeLine.clear();

        this.timeLineItems.forEach((item) => {
            item.elem.removeAttribute('style');
            item.tween.kill();
            item.init()
        });
        
        this.setTimeLine();
        this.scene.setTween(this.timeLine);

        this.isResizing = false;
    }

    setTimeLine()
    {
        this.timeLineItems.forEach((item) => this.timeLine.add(item.tween, item.startAt))
    }

    pauseAnimation() {
        this.timeLine.pause();
    }
    
    resumeAnimation() {
        this.timeLine.resume();
    }

    init(ind=false)
    {
        this.scene.triggerElement(this.triggerElement);
        this.scene.triggerHook(this.triggerHook);
        this.scene.duration(this.getDuration());
        this.scene.offset(this.getOffset());

        this.setTimeLine();

        this.scene.setTween(this.timeLine);
        if (ind) this.scene.addIndicators();
        this.scene.addTo(this.controller);

        window.addEventListener('resize', () => {
            if (!this.isResizing) setTimeout(() => { this.onResize() }, 100);
            this.isResizing = true;
        }, true)
    }

}

class Form {
    constructor(formSelector)
    {
        this.formSelector = formSelector;
        this.form = document.querySelector(this.formSelector);
        this.requried = document.querySelectorAll(formSelector + ' [required]');

        this.init();
    }

    showError(el)
    {
        let errorEl = document.querySelector(el.dataset.requiredTarget);
        errorEl.classList.add('active');
    }

    hideError(el)
    {
        let errorEl = document.querySelector(el.dataset.requiredTarget);
        errorEl.classList.remove('active');
    }

    validate(el)
    {
        let valid = el.validity.valid;

        if (!valid) this.showError(el);
        else this.hideError(el);

        return valid
    }

    onSubmit(e)
    {
        let valid = true;
        let invalidCount = 0;

        this.requried.forEach((el) => {
            if (!this.validate(el)) {
                invalidCount++;
                valid = false;
                if (invalidCount === 1) el.focus()
            }
        });

        if (!valid) e.preventDefault();
    }

    setPoliceman()
    {
        let policeManEl = this.form.querySelector('[name="policeman"]');
        if (!policeManEl) return;
        policeManEl.setAttribute('value', '159357');
    }

    init()
    {
        this.form.addEventListener('submit', (e) => {
            this.onSubmit(e)
        });

        this.setPoliceman();
    }

}

class GoogleMap {
    constructor()
    {
        this.el = null;
        this.map = null;
        this.option = {};
        this.markers = [];
        this.infowindows = [];
        this.initialized = false;

        this.afterInit = () => {}
    }

    addMarker(options, onClick)
    {
        let marker = new google.maps.Marker(options);
        if (typeof onClick === "function") marker.addListener('click', () => { onClick(marker) });
        this.markers.push(marker);
        return marker;
    }

    addInfoWindow(options)
    {
        let infoWindow = new google.maps.InfoWindow(options);
        this.infowindows.push(infoWindow);
        return infoWindow;
    }

    init()
    {
        this.map = new google.maps.Map(this.el, this.option);
        this.initialized = true;
        this.afterInit();
    }

}


class ModalWindow {
    constructor(container)
    {
        this.container = container;
        this.active = false;
        this.animation = null;
        this.modal = null;

        this.afterOpen = () => {};
        this.afterClose = () => {};
    }

    isOpen()
    {
        return this.active
    }

    open(switchWindow)
    {
        this.container.classList.add('active');
        this.active = true;
        this.afterOpen(switchWindow);
    }

    close(switchWindow)
    {
        this.container.classList.remove('active');
        this.active = false;
        this.afterClose(switchWindow);
    }

}

class Modal {
    constructor(container)
    {
        this.container = container;
        this.isOpen = false;
        this.windows = {};

        this.animation = new TweenMax.fromTo(this.container, .5, {opacity: 0}, {opacity: 1}).pause();
    }

    addWindow(key, window)
    {
        window.modal = this;
        this.windows[key] = window
    }

    open(key)
    {
        document.body.classList.add('modal-open');
        document.documentElement.classList.add('modal-open');
        this.container.classList.add('active');
        this.openWindowKey = key;
        this.animation.play();
        this.isOpen = true;

        this.windows[this.openWindowKey].open();
    }

    close()
    {
        this.windows[this.openWindowKey].close();
        this.animation.reverse();
    }

    switch(key)
    {
        this.windows[this.openWindowKey].close(true);
        this.openWindowKey = key;
        this.windows[this.openWindowKey].open(true);
    }

    lateClose()
    {
        document.body.classList.remove('modal-open');
        document.documentElement.classList.remove('modal-open');
        this.container.classList.remove('active');
        this.isOpen = false;
    }
}


class Cookies {
    constructor(container, button) {
        this.container = container;
        this.button = button;

        this.setEvents()
    }

    setEvents() {
        this.button.addEventListener('click', () => this.close(true))
    }

    close(set) {
        if (set) Cookies.setCookie('cookies-policy', true, 365);
        this.container.classList.remove('active')
    }

    check() {
        if (Cookies.getCookie('cookies-policy')) {
            this.close()
        }
    }

    static setCookie(name, val, expiration) {
        let d = new Date();
        d.setTime(d.getTime() + (expiration * 24 * 60 * 60 * 1000));
        let expires = "expires="+d.toUTCString();
        document.cookie = name + "=" + val + ";" + expires + ";path=/";
    }

    static getCookie(cname) {
        let name = cname + "=";
        let ca = document.cookie.split(';');
        for(let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1);
            if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
        }
        return "";
    }
}

class TextSlider {
    constructor(container, content)
    {
        this.container = container;
        this.content = content.split(',');

        this.activeIndex = 0;
        this.nextIndex = 0;

        this.animateLoop = null;
        this.filling = false;
        this.pause = false;

        this.isInitialized = false;
    }

    static loopIndex(index, arr) {
        if (index+1 > arr.length-1) return 0;
        else return index+1
    }

    animate() {

        clearInterval(this.animateLoop);

        this.filling = false;
        this.activeIndex = this.nextIndex;
        this.nextIndex = TextSlider.loopIndex(this.activeIndex, this.content);

        this.animateLoop = setInterval(() => {

            if (!this.filling) {
                this.container.innerText = this.container.innerText.substr(0, this.container.innerText.length-1)
                if (this.container.innerText === "") {
                    this.filling = true
                }
            } else {
                if (this.container.innerText !== this.content[this.nextIndex]) {
                    this.container.innerText += this.content[this.nextIndex][(this.container.innerText.length-1)+1]
                }
            }
        }, 125);
    }

    init() {
        this.isInitialized = true;

        setInterval(() => {
            if (!this.pause) this.animate()
        }, 3000)
    }

}
/******************************************
 * Nav
 ******************************************/
const nav = new Nav(document.querySelector('.nav-toggler'),
  document.querySelector('.nav'),
  document.querySelectorAll('.nav__toggler-close'),
  document.getElementById('header'),
  document.getElementById('header'))
  .init();
const onNavToggleElements = document.querySelectorAll('.nav-on-toggle');

nav.onOpen = () => {
  onNavToggleElements.forEach(el => el.classList.add('active'))
};

nav.onClose = () => {
  onNavToggleElements.forEach(el => el.classList.remove('active'))
};

/******************************************
 * Cookies
 ******************************************/
let cookiesBar = new Cookies(
  document.querySelector('.cookies'),
  document.querySelector('.cookies__inner__button')
).check();

/******************************************
 * Animations
 ******************************************/
const scrollMagicController = new ScrollMagic.Controller();

var squareContainer = document.querySelector('.square');
var artContainer = document.getElementById('art');
var introTeam = document.querySelector('.introteam');
var aboutContainer = document.getElementById('about');
var triangleContainer = document.querySelector('.triangle');
var servicesContainer = document.getElementById('services');
var ovalContainer = document.querySelector('.oval');
var peopleContainer = document.getElementById('people');

var teamContainer = document.getElementById('team');
var futureContainer = document.getElementById('future');



// squareContainer
var squareAnimationSmallPart = new ScrollPart(".square__shape-small", 1);
squareAnimationSmallPart.getFrom = () => {
  return {
    top: '50vh',
    height: (artContainer.clientHeight / 10) * 2,
    width: (artContainer.clientHeight / 10) * 2,
    opacity: 0.9
  }
};
squareAnimationSmallPart.getTo = () => {
  return {
    top: window.innerHeight + artContainer.clientHeight / 2,
    height: '100vh',
    width: artContainer.clientHeight,
    opacity: 1,
    ease: Power2.easeInOut
  }
};
squareAnimationSmallPart.init();



var squareAnimationBgPart = new ScrollPart(".square__bg", 1);
squareAnimationBgPart.getFrom = () => {
  return {opacity: 0}
};
squareAnimationBgPart.getTo = () => {
  return {opacity: 1}
};
squareAnimationBgPart.init();



var squareAnimationArrow = new ScrollPart(".scroll-arrow", 0.25);
squareAnimationArrow.getFrom = () => {
  return {opacity: 1}
};
squareAnimationArrow.getTo = () => {
  return {opacity: 0}
};
squareAnimationArrow.init();


var squareAnimation = new ScrollScene(scrollMagicController, squareContainer, 0);

squareAnimation.getDuration = () => {
  return "100%"
};

squareAnimation.timeLineItems = [
  squareAnimationSmallPart,
  squareAnimationBgPart,
  squareAnimationArrow,
];

squareAnimation.init();


// artContainer
var artAnimationTitle = new ScrollPart(".art-animation-title", 1,);
artAnimationTitle.getFrom = () => {
  return {y: 500}
};
artAnimationTitle.getTo = () => {
  return {y: 0}
};
artAnimationTitle.init();

var artAnimation = new ScrollScene(scrollMagicController, artContainer, 0);
artAnimation.getDuration = () => {
  return "100%"
};
artAnimation.getOffset = () => {
  return -artContainer.clientHeight
};
artAnimation.timeLineItems = [
  artAnimationTitle,
];
artAnimation.init();



// introTeam
// var introTeamAnimationMovingMask1 = new ScrollPart(".movingMask1", 1);
// introTeamAnimationMovingMask1.getFrom = () => {
//   return {
//     // scale: 2,
//     transformOrigin:"center center",
//     // attr: { cx: "130" }
//     transform: "translateX(-200px)"
//   }
// };
// introTeamAnimationMovingMask1.getTo = () => {
//   return {
//     // scale: 1,
//     // attr: { cx: "335" },
//     transform: "translateX(0px)",
//     ease: Power2.easeInOut
//   }
// };
// introTeamAnimationMovingMask1.init();


// var introTeamAnimationMovingCircle1 = new ScrollPart(".movingCircle1", 1);
// introTeamAnimationMovingCircle1.getFrom = () => {
//   return {
//     // scale: 2,
//     transformOrigin:"center center",
//     transform: "translateX(-200px)"
//     // attr: { cx: "130" }
//   }
// };
// introTeamAnimationMovingCircle1.getTo = () => {
//   return {
//     // scale: 1,
//     // attr: { cx: "335" },
//     transform: "translateX(0px)",
//     ease: Power2.easeInOut
//   }
// };
// introTeamAnimationMovingCircle1.init();

var introTeamAnimationinContainer = new ScrollPart(".introteam__anim", 1);
introTeamAnimationinContainer.getFrom = () => {
  return {
    top: "10%"
  }
};
introTeamAnimationinContainer.getTo = () => {
  return {
    top: "55%",
    ease: Power2.easeInOut
  }
};
introTeamAnimationinContainer.init();

var introTeamAnimation = new ScrollScene(scrollMagicController, introTeam, 0);
introTeamAnimation.getDuration = () => {
  return "50%"
};
introTeamAnimation.getOffset = () => {
  if (window.matchMedia("(max-width: 768px)").matches) {
    return -introTeam.clientHeight;
  } else {
    return 0; // Return a default or different value for non-mobile screens
  }
};
introTeamAnimation.timeLineItems = [
  // introTeamAnimationMovingMask1,
  // introTeamAnimationMovingCircle1,
  introTeamAnimationinContainer
];
introTeamAnimation.init();


/** About */
var aboutAnimationLogo = new ScrollPart(".about-animation-logo", .7);
aboutAnimationLogo.getFrom = () => {
  return {x: -500, opacity: 0}
};
aboutAnimationLogo.getTo = () => {
  return {x: 0, opacity: 1}
};
aboutAnimationLogo.init();

var aboutAnimationText = new ScrollPart(".about-animation-text", .8);
aboutAnimationText.getFrom = () => {
  return {x: -500, opacity: 0}
};
aboutAnimationText.getTo = () => {
  return {x: 0, opacity: 1}
};
aboutAnimationText.init();

var aboutAnimationCitation = new ScrollPart(".about-animation-citation", .9);
aboutAnimationCitation.getFrom = () => {
  return {x: -500, opacity: 0}
};
aboutAnimationCitation.getTo = () => {
  return {x: 0, opacity: 1}
};
aboutAnimationCitation.init();

var aboutAnimation = new ScrollScene(scrollMagicController, aboutContainer, 0);
aboutAnimation.getDuration = () => {
  return '60%'
};
aboutAnimation.getOffset = () => {
  return -aboutContainer.offsetHeight
};
aboutAnimation.timeLineItems = [
  aboutAnimationLogo,
  aboutAnimationText,
  aboutAnimationCitation,
];
aboutAnimation.init();



/** Violet triangle scene */

triangleContainer.style.height = triangleContainer.clientHeight + aboutContainer.offsetHeight + 'px';
var triangleAnimationLeft = new ScrollPart(".triangle__shape-left", 1);
triangleAnimationLeft.getFrom = () => {
  return {y: 500, x: -500}
};
triangleAnimationLeft.getTo = () => {
  return {y: 0, x: 0, ease: Power2.easeInOut}
};
triangleAnimationLeft.init();

var triangleAnimationRight = new ScrollPart(".triangle__shape-right", 1);
triangleAnimationRight.getFrom = () => {
  return {y: triangleContainer.clientHeight / 2, x: 300}
};
triangleAnimationRight.getTo = () => {
  return {y: 0, x: 0, ease: Power2.easeInOut}
};
triangleAnimationRight.init();

var triangleAnimationBg = new ScrollPart(".triangle-bg", 1, .75);
triangleAnimationBg.getFrom = () => {
  return {opacity: 0, y: -100}
};
triangleAnimationBg.getTo = () => {
  return {opacity: 1, y: 0}
};
triangleAnimationBg.init();

var triangleAnimation = new ScrollScene(scrollMagicController, triangleContainer, 0);
triangleAnimation.getDuration = () => {
  return aboutContainer.offsetHeight * 2
};
triangleAnimation.getOffset = () => {
  return -aboutContainer.offsetHeight
};
triangleAnimation.timeLineItems = [
  triangleAnimationLeft,
  triangleAnimationRight,
  triangleAnimationBg,
];
triangleAnimation.init();


/** Services */
var servicesAnimationTitle = new ScrollPart(".services-animation-title", 1,);
servicesAnimationTitle.getFrom = () => {
  return {x: 500, opacity: 0}
};
servicesAnimationTitle.getTo = () => {
  return {x: 0, opacity: 1}
};
servicesAnimationTitle.init();

var servicesAnimationCol1 = new ScrollPart(".services-animation-col-1", .8,);
servicesAnimationCol1.getFrom = () => {
  return {y: 500, opacity: 0}
};
servicesAnimationCol1.getTo = () => {
  return {y: 0, opacity: 1}
};
servicesAnimationCol1.init();

var servicesAnimationCol2 = new ScrollPart(".services-animation-col-2", .9,);
servicesAnimationCol2.getFrom = () => {
  return {y: 500, opacity: 0}
};
servicesAnimationCol2.getTo = () => {
  return {y: 0, opacity: 1}
};
servicesAnimationCol2.init();

var servicesAnimationCol3 = new ScrollPart(".services-animation-col-3", 1,);
servicesAnimationCol3.getFrom = () => {
  return {y: 500, opacity: 0}
};
servicesAnimationCol3.getTo = () => {
  return {y: 0, opacity: 1}
};
servicesAnimationCol3.init();

var servicesAnimation = new ScrollScene(scrollMagicController, servicesContainer);
servicesAnimation.getDuration = () => {
  return window.outerHeight
};
servicesAnimation.getOffset = () => {
  return -window.outerHeight * 0.75
};
servicesAnimation.timeLineItems = [
  servicesAnimationTitle,
  servicesAnimationCol1,
  servicesAnimationCol2,
  servicesAnimationCol3,
];
servicesAnimation.init();



/** Oval (yellow circle) scene */

var ovalAnimationPart = new ScrollPart(".oval__shape", 1, 1.4);
ovalAnimationPart.getFrom = () => {
  return {
    left: "20%",
    bottom: window.innerHeight > 1200 ? '75%' : '150vh',
  }
};
ovalAnimationPart.getTo = () => {
  return {
    left: "50%",
    bottom: window.innerHeight > 1200 ? '25%' : '50vh',
    ease: Power2.easeInOut,
  }
};
ovalAnimationPart.init();

var ovalAnimationPartWidth = new ScrollPart(".oval__shape", .5, 2);
ovalAnimationPartWidth.getFrom = () => {
  return {
    width: (ovalContainer.clientWidth / 100) * 60,
    height: (ovalContainer.clientWidth / 100) * 60,
  }
};
ovalAnimationPartWidth.getTo = () => {
  return {
    width: (ovalContainer.clientWidth / 100) * 100,
    height: (ovalContainer.clientWidth / 100) * 100, ease: Power2.easeInOut,
  }
};
ovalAnimationPartWidth.init();

var ovalAnimationPartScale = new ScrollPart(".oval__shape", 0.4, 1);
ovalAnimationPartScale.getFrom = () => {
  return {
    scale: 0,
    x: '-50%',
    y: '50%',
  }
};
ovalAnimationPartScale.getTo = () => {
  return {
    scale: 1,
    x: '-50%',
    y: '50%',
    ease: Power2.easeInOut
  }
};
ovalAnimationPartScale.init();


// WALL

var ovalWallAnimationPart = new ScrollPart(".oval__wallShape", 1, 1.4);
ovalWallAnimationPart.getFrom = () => {
  return {
    left: "20%",
    bottom: window.innerHeight > 1200 ? '75%' : '150vh',
  }
};
ovalWallAnimationPart.getTo = () => {
  return {
    left: "20%",
    bottom: window.innerHeight > 1200 ? '75%' : '150vh',
  }
};
ovalWallAnimationPart.init();

var ovalWallAnimationPartScale = new ScrollPart(".oval__wallShape", 0.4, 1);
ovalWallAnimationPartScale.getFrom = () => {
  return {
    scale: 0,
    x: '-50%',
    y: '50%',
  }
};
ovalWallAnimationPartScale.getTo = () => {
  return {
    scale: 1,
    x: '-50%',
    y: '50%',
    ease: Power2.easeInOut
  }
};
ovalWallAnimationPartScale.init();


var ovalAnimation = new ScrollScene(scrollMagicController, ovalContainer, 0);
ovalAnimation.getDuration = () => {
  return ovalContainer.clientHeight + servicesContainer.clientHeight
};
ovalAnimation.getOffset = () => {
  return -(ovalContainer.clientHeight / 2 + servicesContainer.clientHeight)
};
ovalAnimation.timeLineItems = [
  ovalAnimationPart,
  ovalAnimationPartWidth,
  ovalAnimationPartScale,
  ovalWallAnimationPart,
  ovalWallAnimationPartScale,
];
ovalAnimation.init();



// peopleContainer

const arrowLeft = document.querySelector('.arrow-left');
const arrowRight = document.querySelector('.arrow-right');

let isDragging = false;
let startX = 0;
let currentTranslateX = 0;

let prevTranslateX = 0; // Udržuje předešlou hodnotu translateX
let startTouchX = 0; // Ukládá startovní pozici dotyku na mobilu

// Funkce pro získání aktuální hodnoty translateX
function getCurrentTranslateX(element) {
  const style = window.getComputedStyle(element);
  const matrix = new DOMMatrixReadOnly(style.transform);
  return matrix.m41; // m41 obsahuje hodnotu translateX
}

// Funkce pro aktualizaci transformace s novou hodnotou
function updateTranslateX(element, increment) {
  const currentX = getCurrentTranslateX(element);
  const newX = currentX + increment;
  peopleContainer.style.transition = `transform 0.15s ease-in-out`;
  element.style.transform = `translate(${newX}px, 0)`;
  setTimeout(()=> {
    peopleContainer.style.transition = `none`;
  }, 500)
}

// Funkce pro zpracování začátku drag/touch
function onDragStart(event) {
  isDragging = true;
  startX = event.type.includes('mouse') ? event.clientX : event.touches[0].clientX; // Pro myš i touch
  currentTranslateX = getCurrentTranslateX(peopleContainer);
}

// Funkce pro zpracování pohybu drag/touch
function onDragMove(event) {
  if (!isDragging) return;
  const currentX = event.type.includes('mouse') ? event.clientX : event.touches[0].clientX; // Pro myš i touch
  const deltaX = currentX - startX;
  peopleContainer.style.transform = `translate(${currentTranslateX + deltaX}px, 0)`;
  peopleContainer.style.transition = `none`; // Žádná animace během tažení
}

// Funkce pro zpracování konce drag/touch
function onDragEnd() {
  isDragging = false;
}


// Funkce pro ověření, zda lze kontejner ještě posouvat vlevo
function canMoveLeft() {
  const currentX = getCurrentTranslateX(peopleContainer);
  
  return currentX < peopleContainer.offsetWidth*0.9; // Pokud je currentX menší než 0, lze posouvat vlevo
}

// Funkce pro ověření, zda lze kontejner ještě posouvat vpravo
function canMoveRight() {
  const containerWidth = peopleContainer.offsetWidth;
  const viewportWidth = peopleContainer.parentElement.offsetWidth;
  const currentX = getCurrentTranslateX(peopleContainer);


  console.log(currentX, peopleContainer.offsetWidth*1)
  return currentX > 0; // Zajistí, že kontejner není úplně vpravo
}

// Event listenery pro desktop
// peopleContainer.addEventListener('mousedown', onDragStart);
// window.addEventListener('mousemove', onDragMove);
// window.addEventListener('mouseup', onDragEnd);

// Event listenery pro mobilní zařízení (touch)
peopleContainer.addEventListener('touchstart', onDragStart);
window.addEventListener('touchmove', onDragMove);
window.addEventListener('touchend', onDragEnd);

arrowLeft.addEventListener('click', () => {
  if (canMoveRight()) {
    setTimeout(() => {
      updateTranslateX(peopleContainer, -0.2 * peopleContainer.offsetWidth);  
    }, 150);
  }  
});

arrowRight.addEventListener('click', () => {
  if (canMoveLeft()) {
    setTimeout(() => {
      updateTranslateX(peopleContainer, 0.2 * peopleContainer.offsetWidth);
    }, 150);
  }
});
  


var peopleAnimationPartScale = new ScrollPart(".people", 1.2, 1);
peopleAnimationPartScale.getFrom = () => {
  return {
    x: "100%",
  }
};
peopleAnimationPartScale.getTo = () => {
  return {
    x: "60%",
  }

};
peopleAnimationPartScale.init();

var peopleAnimation = new ScrollScene(scrollMagicController, peopleContainer, 0);
peopleAnimation.getDuration = () => {
  return ovalContainer.clientHeight + servicesContainer.clientHeight;
};
peopleAnimation.getOffset = () => {
  if (window.matchMedia("(max-width: 768px)").matches) {
    return -ovalContainer.clientHeight*1.2;
  } else {
    return -(ovalContainer.clientHeight / 2 + servicesContainer.clientHeight);
  }
  
};
peopleAnimation.timeLineItems = [
  peopleAnimationPartScale,
];
peopleAnimation.init();




/******************************************
 * SVG claim animation - notworking now
 ******************************************/
let svgClaimItems = document.querySelectorAll('.about-animation-logo-fadein');
let svgClaimItemsActive = false;
let svgClaimScene = new ScrollMagic.Scene({
  triggerElement: "#about",
  triggerHook: 0.5,
  duration: "100%",
}).addTo(scrollMagicController);

svgClaimScene.on('enter', function () {
  if (!svgClaimItemsActive) {
    svgClaimItemsActive = true;
    setTimeout(() => {
      svgClaimItems.forEach((item) => {
        item.setAttribute('class', 'about-animation-logo-fadein active');
      });
    }, 2000)
  }
});

/******************************************
 * Text Slider
 ******************************************/
let textSlider = new TextSlider(document.querySelector('.text-slider span'), textSliderContent);
textSlider.scene = new ScrollMagic.Scene({
  triggerElement: "#offer",
  triggerHook: 0.5,
  duration: "100%",
}).addTo(scrollMagicController);

textSlider.scene.on('enter', function () {
  textSlider.pause = false;
  if (!textSlider.isInitialized) textSlider.init()
});
textSlider.scene.on('leave', function () {
  textSlider.pause = true
});

/******************************************
 * Smooth scroll
 ******************************************/

// zenscroll.setup(1400, 120);

if (location.hash !== "") {
  let target = document.getElementById(location.hash.substr(1, location.hash.length));
  if (target) zenscroll.center(target, 500)
}

const scrollToBtns = document.querySelectorAll('[data-scroll-to]');
scrollToBtns.forEach((btn) => {
  btn.addEventListener('click', () => {
    let target = document.querySelector(btn.dataset.scrollTo);
    zenscroll.center(target)
  })
});


/******************************************
 * Forms
 ******************************************/

const contactForm = new Form('.contact-form');
const nwsForm = new Form('.nws-form');


/******************************************
 * Modal windows
 ******************************************/

var modal = new Modal(document.querySelector('.modal'));

// Marek Martinka
var modalWindowMartinka = new ModalWindow(document.getElementById('modal-marek-martinka'));
var modalWindowMartinkaAnimationMain = new TweenMax.fromTo(modalWindowMartinka.container, .3, {opacity: 0}, {opacity: 1}).pause();
var modalWindowMartinkaAnimationLeft = new TweenMax.fromTo('.modal-left', .6, {y: -500}, {y: 0}).pause();
var modalWindowMartinkaAnimationRight = new TweenMax.fromTo('.modal-right', .6, {y: 500}, {y: 0}).pause();

modalWindowMartinka.afterOpen = (switchWindow) => {
  modalWindowMartinka.container.scrollTop = 0;
  modalWindowMartinkaAnimationMain.play();
  modalWindowMartinkaAnimationLeft.play();
  modalWindowMartinkaAnimationRight.play();
};

modalWindowMartinka.afterClose = (switchWindow) => {
  modalWindowMartinkaAnimationMain.reverse();
  modalWindowMartinkaAnimationLeft.reverse();
  modalWindowMartinkaAnimationRight.reverse();

  if (!switchWindow) {
    setTimeout(() => {
      modalWindowJanFsicher.modal.lateClose()
      modalWindowAdamDanielKraus.modal.lateClose()
      modalWindowNikolaAichingerova.modal.lateClose()
      modalWindowTimonSvoboda.modal.lateClose()
      modalWindowAnnaHavrankova.modal.lateClose()
      modalWindowVeronikaOtahalova.modal.lateClose()
      modalWindowTereziePistkova.modal.lateClose()
      modalWindowAnnaMartinkova.modal.lateClose()
      modalWindowColleagues.modal.lateClose()
    }, 600)
  }
};

// Jan Fischer
var modalWindowJanFsicher = new ModalWindow(document.getElementById('modal-jan-fischer'));
var modalWindowJanFsicherAnimationMain = new TweenMax.fromTo(modalWindowJanFsicher.container, .3, {opacity: 0}, {opacity: 1}).pause();
var modalWindowJanFsicherAnimationLeft = new TweenMax.fromTo('.modal-left', .6, {y: -500}, {y: 0}).pause();
var modalWindowJanFsicherAnimationRight = new TweenMax.fromTo('.modal-right', .6, {y: 500}, {y: 0}).pause();

modalWindowJanFsicher.afterOpen = (switchWindow) => {
  modalWindowJanFsicher.container.scrollTop = 0;
  modalWindowJanFsicherAnimationMain.play();
  modalWindowJanFsicherAnimationLeft.play();
  modalWindowJanFsicherAnimationRight.play();
};

modalWindowJanFsicher.afterClose = (switchWindow) => {
  modalWindowJanFsicherAnimationMain.reverse();
  modalWindowJanFsicherAnimationLeft.reverse();
  modalWindowJanFsicherAnimationRight.reverse();

  if (!switchWindow) {
    setTimeout(() => {
      modalWindowMartinka.modal.lateClose()
      modalWindowAdamDanielKraus.modal.lateClose()
      modalWindowNikolaAichingerova.modal.lateClose()
      modalWindowTimonSvoboda.modal.lateClose()
      modalWindowAnnaHavrankova.modal.lateClose()
      modalWindowVeronikaOtahalova.modal.lateClose()
      modalWindowTereziePistkova.modal.lateClose()
      modalWindowAnnaMartinkova.modal.lateClose()
      modalWindowColleagues.modal.lateClose()
    }, 600)
  }

};

// Adam Daniel Kraus
var modalWindowAdamDanielKraus = new ModalWindow(document.getElementById('modal-adam-daniel-kraus'));
var modalWindowAdamDanielKrausAnimationMain = new TweenMax.fromTo(modalWindowAdamDanielKraus.container, .3, {opacity: 0}, {opacity: 1}).pause();
var modalWindowAdamDanielKrausAnimationLeft = new TweenMax.fromTo('.modal-left', .6, {y: -500}, {y: 0}).pause();
var modalWindowAdamDanielKrausAnimationRight = new TweenMax.fromTo('.modal-right', .6, {y: 500}, {y: 0}).pause();

modalWindowAdamDanielKraus.afterOpen = (switchWindow) => {
  modalWindowAdamDanielKraus.container.scrollTop = 0;
  modalWindowAdamDanielKrausAnimationMain.play();
  modalWindowAdamDanielKrausAnimationLeft.play();
  modalWindowAdamDanielKrausAnimationRight.play();
};

modalWindowAdamDanielKraus.afterClose = (switchWindow) => {
  modalWindowAdamDanielKrausAnimationMain.reverse();
  modalWindowAdamDanielKrausAnimationLeft.reverse();
  modalWindowAdamDanielKrausAnimationRight.reverse();

  if (!switchWindow) {
    setTimeout(() => {
      modalWindowMartinka.modal.lateClose()
      modalWindowJanFsicher.modal.lateClose()
      modalWindowNikolaAichingerova.modal.lateClose()
      modalWindowTimonSvoboda.modal.lateClose()
      modalWindowAnnaHavrankova.modal.lateClose()
      modalWindowVeronikaOtahalova.modal.lateClose()
      modalWindowTereziePistkova.modal.lateClose()
      modalWindowAnnaMartinkova.modal.lateClose()
      modalWindowColleagues.modal.lateClose()
    }, 600)
  }

};

// Nikola Aichingerová
var modalWindowNikolaAichingerova = new ModalWindow(document.getElementById('modal-nikola-aichingerova'));
var modalWindowNikolaAichingerovaAnimationMain = new TweenMax.fromTo(modalWindowNikolaAichingerova.container, .3, {opacity: 0}, {opacity: 1}).pause();
var modalWindowNikolaAichingerovaAnimationLeft = new TweenMax.fromTo('.modal-left', .6, {y: -500}, {y: 0}).pause();
var modalWindowNikolaAichingerovaAnimationRight = new TweenMax.fromTo('.modal-right', .6, {y: 500}, {y: 0}).pause();

modalWindowNikolaAichingerova.afterOpen = (switchWindow) => {
  modalWindowNikolaAichingerova.container.scrollTop = 0;
  modalWindowNikolaAichingerovaAnimationMain.play();
  modalWindowNikolaAichingerovaAnimationLeft.play();
  modalWindowNikolaAichingerovaAnimationRight.play();
};

modalWindowNikolaAichingerova.afterClose = (switchWindow) => {
  modalWindowNikolaAichingerovaAnimationMain.reverse();
  modalWindowNikolaAichingerovaAnimationLeft.reverse();
  modalWindowNikolaAichingerovaAnimationRight.reverse();

  if (!switchWindow) {
    setTimeout(() => {
      modalWindowMartinka.modal.lateClose()
      modalWindowJanFsicher.modal.lateClose()
      modalWindowAdamDanielKraus.modal.lateClose()
      modalWindowTimonSvoboda.modal.lateClose()
      modalWindowAnnaHavrankova.modal.lateClose()
      modalWindowVeronikaOtahalova.modal.lateClose()
      modalWindowTereziePistkova.modal.lateClose()
      modalWindowAnnaMartinkova.modal.lateClose()
      modalWindowColleagues.modal.lateClose()
    }, 600)
  }

};

// Timon Svoboda
var modalWindowTimonSvoboda = new ModalWindow(document.getElementById('modal-timon-svoboda'));
var modalWindowTimonSvobodaAnimationMain = new TweenMax.fromTo(modalWindowTimonSvoboda.container, .3, {opacity: 0}, {opacity: 1}).pause();
var modalWindowTimonSvobodaAnimationLeft = new TweenMax.fromTo('.modal-left', .6, {y: -500}, {y: 0}).pause();
var modalWindowTimonSvobodaAnimationRight = new TweenMax.fromTo('.modal-right', .6, {y: 500}, {y: 0}).pause();

modalWindowTimonSvoboda.afterOpen = (switchWindow) => {
  modalWindowTimonSvoboda.container.scrollTop = 0;
  modalWindowTimonSvobodaAnimationMain.play();
  modalWindowTimonSvobodaAnimationLeft.play();
  modalWindowTimonSvobodaAnimationRight.play();
};

modalWindowTimonSvoboda.afterClose = (switchWindow) => {
  modalWindowTimonSvobodaAnimationMain.reverse();
  modalWindowTimonSvobodaAnimationLeft.reverse();
  modalWindowTimonSvobodaAnimationRight.reverse();

  if (!switchWindow) {
    setTimeout(() => {
      modalWindowMartinka.modal.lateClose()
      modalWindowJanFsicher.modal.lateClose()
      modalWindowAdamDanielKraus.modal.lateClose()
      modalWindowNikolaAichingerova.modal.lateClose()
      modalWindowAnnaHavrankova.modal.lateClose()
      modalWindowVeronikaOtahalova.modal.lateClose()
      modalWindowTereziePistkova.modal.lateClose()
      modalWindowAnnaMartinkova.modal.lateClose()
      modalWindowColleagues.modal.lateClose()
    }, 600)
  }

};

// Anna Havránková
var modalWindowAnnaHavrankova = new ModalWindow(document.getElementById('modal-anna-havrankova'));
var modalWindowAnnaHavrankovaAnimationMain = new TweenMax.fromTo(modalWindowAnnaHavrankova.container, .3, {opacity: 0}, {opacity: 1}).pause();
var modalWindowAnnaHavrankovaAnimationLeft = new TweenMax.fromTo('.modal-left', .6, {y: -500}, {y: 0}).pause();
var modalWindowAnnaHavrankovaAnimationRight = new TweenMax.fromTo('.modal-right', .6, {y: 500}, {y: 0}).pause();

modalWindowAnnaHavrankova.afterOpen = (switchWindow) => {
  modalWindowAnnaHavrankova.container.scrollTop = 0;
  modalWindowAnnaHavrankovaAnimationMain.play();
  modalWindowAnnaHavrankovaAnimationLeft.play();
  modalWindowAnnaHavrankovaAnimationRight.play();
};

modalWindowAnnaHavrankova.afterClose = (switchWindow) => {
  modalWindowAnnaHavrankovaAnimationMain.reverse();
  modalWindowAnnaHavrankovaAnimationLeft.reverse();
  modalWindowAnnaHavrankovaAnimationRight.reverse();

  if (!switchWindow) {
    setTimeout(() => {
      modalWindowMartinka.modal.lateClose()
      modalWindowJanFsicher.modal.lateClose()
      modalWindowAdamDanielKraus.modal.lateClose()
      modalWindowNikolaAichingerova.modal.lateClose()
      modalWindowTimonSvoboda.modal.lateClose()
      modalWindowVeronikaOtahalova.modal.lateClose()
      modalWindowTereziePistkova.modal.lateClose()
      modalWindowAnnaMartinkova.modal.lateClose()
      modalWindowColleagues.modal.lateClose()
    }, 600)
  }

};

// Veronika Otáhalová
var modalWindowVeronikaOtahalova = new ModalWindow(document.getElementById('modal-veronika-otahalova'));
var modalWindowVeronikaOtahalovaAnimationMain = new TweenMax.fromTo(modalWindowVeronikaOtahalova.container, .3, {opacity: 0}, {opacity: 1}).pause();
var modalWindowVeronikaOtahalovaAnimationLeft = new TweenMax.fromTo('.modal-left', .6, {y: -500}, {y: 0}).pause();
var modalWindowVeronikaOtahalovaAnimationRight = new TweenMax.fromTo('.modal-right', .6, {y: 500}, {y: 0}).pause();

modalWindowVeronikaOtahalova.afterOpen = (switchWindow) => {
  modalWindowVeronikaOtahalova.container.scrollTop = 0;
  modalWindowVeronikaOtahalovaAnimationMain.play();
  modalWindowVeronikaOtahalovaAnimationLeft.play();
  modalWindowVeronikaOtahalovaAnimationRight.play();
};

modalWindowVeronikaOtahalova.afterClose = (switchWindow) => {
  modalWindowVeronikaOtahalovaAnimationMain.reverse();
  modalWindowVeronikaOtahalovaAnimationLeft.reverse();
  modalWindowVeronikaOtahalovaAnimationRight.reverse();

  if (!switchWindow) {
    setTimeout(() => {
      modalWindowMartinka.modal.lateClose()
      modalWindowJanFsicher.modal.lateClose()
      modalWindowAdamDanielKraus.modal.lateClose()
      modalWindowNikolaAichingerova.modal.lateClose()
      modalWindowTimonSvoboda.modal.lateClose()
      modalWindowAnnaHavrankova.modal.lateClose()
      modalWindowTereziePistkova.modal.lateClose()
      modalWindowAnnaMartinkova.modal.lateClose()
      modalWindowColleagues.modal.lateClose()
    }, 600)
  }

};

// Terezie Píšťková 
var modalWindowTereziePistkova = new ModalWindow(document.getElementById('modal-terezie-pistkova'));
var modalWindowTereziePistkovaAnimationMain = new TweenMax.fromTo(modalWindowTereziePistkova.container, .3, {opacity: 0}, {opacity: 1}).pause();
var modalWindowTereziePistkovaAnimationLeft = new TweenMax.fromTo('.modal-left', .6, {y: -500}, {y: 0}).pause();
var modalWindowTereziePistkovaAnimationRight = new TweenMax.fromTo('.modal-right', .6, {y: 500}, {y: 0}).pause();

modalWindowTereziePistkova.afterOpen = (switchWindow) => {
  modalWindowTereziePistkova.container.scrollTop = 0;
  modalWindowTereziePistkovaAnimationMain.play();
  modalWindowTereziePistkovaAnimationLeft.play();
  modalWindowTereziePistkovaAnimationRight.play();
};

modalWindowTereziePistkova.afterClose = (switchWindow) => {
  modalWindowTereziePistkovaAnimationMain.reverse();
  modalWindowTereziePistkovaAnimationLeft.reverse();
  modalWindowTereziePistkovaAnimationRight.reverse();

  if (!switchWindow) {
    setTimeout(() => {
      modalWindowMartinka.modal.lateClose()
      modalWindowJanFsicher.modal.lateClose()
      modalWindowAdamDanielKraus.modal.lateClose()
      modalWindowNikolaAichingerova.modal.lateClose()
      modalWindowTimonSvoboda.modal.lateClose()
      modalWindowAnnaHavrankova.modal.lateClose()
      modalWindowVeronikaOtahalova.modal.lateClose()
      modalWindowAnnaMartinkova.modal.lateClose()
      modalWindowColleagues.modal.lateClose()
    }, 600)
  }

};

// Anna Martinková
var modalWindowAnnaMartinkova = new ModalWindow(document.getElementById('modal-anna-martinkova'));
var modalWindowAnnaMartinkovaAnimationMain = new TweenMax.fromTo(modalWindowAnnaMartinkova.container, .3, {opacity: 0}, {opacity: 1}).pause();
var modalWindowAnnaMartinkovaAnimationLeft = new TweenMax.fromTo('.modal-left', .6, {y: -500}, {y: 0}).pause();
var modalWindowAnnaMartinkovaAnimationRight = new TweenMax.fromTo('.modal-right', .6, {y: 500}, {y: 0}).pause();

modalWindowAnnaMartinkova.afterOpen = (switchWindow) => {
  modalWindowAnnaMartinkova.container.scrollTop = 0;
  modalWindowAnnaMartinkovaAnimationMain.play();
  modalWindowAnnaMartinkovaAnimationLeft.play();
  modalWindowAnnaMartinkovaAnimationRight.play();
};

modalWindowAnnaMartinkova.afterClose = (switchWindow) => {
  modalWindowAnnaMartinkovaAnimationMain.reverse();
  modalWindowAnnaMartinkovaAnimationLeft.reverse();
  modalWindowAnnaMartinkovaAnimationRight.reverse();

  if (!switchWindow) {
    setTimeout(() => {
      modalWindowMartinka.modal.lateClose()
      modalWindowJanFsicher.modal.lateClose()
      modalWindowAdamDanielKraus.modal.lateClose()
      modalWindowNikolaAichingerova.modal.lateClose()
      modalWindowTimonSvoboda.modal.lateClose()
      modalWindowAnnaHavrankova.modal.lateClose()
      modalWindowVeronikaOtahalova.modal.lateClose()
      modalWindowTereziePistkova.modal.lateClose()
      modalWindowColleagues.modal.lateClose()
    }, 600)
  }

};



// Kolegové
var modalWindowColleagues = new ModalWindow(document.getElementById('modal-colleagues'));
var modalWindowColleaguesAnimationMain = new TweenMax.fromTo(modalWindowColleagues.container, .3, {opacity: 0}, {opacity: 1}).pause();
var modalWindowColleaguesAnimationContent = new TweenMax.fromTo('.modal-colleagues__animation-content', .6, {y: -500}, {y: 0}).pause();

modalWindowColleagues.afterOpen = (switchWindow) => {
  modalWindowColleagues.container.scrollTop = 0;
  modalWindowColleaguesAnimationMain.play();
  modalWindowColleaguesAnimationContent.play();
};

modalWindowColleagues.afterClose = (switchWindow) => {
  modalWindowColleaguesAnimationMain.reverse();
  modalWindowColleaguesAnimationContent.reverse();

  if (!switchWindow) {
    setTimeout(() => {
      modalWindowMartinka.modal.lateClose()
      modalWindowJanFsicher.modal.lateClose()
      modalWindowAdamDanielKraus.modal.lateClose()
      modalWindowNikolaAichingerova.modal.lateClose()
      modalWindowTimonSvoboda.modal.lateClose()
      modalWindowAnnaHavrankova.modal.lateClose()
      modalWindowVeronikaOtahalova.modal.lateClose()
      modalWindowTereziePistkova.modal.lateClose()
      modalWindowAnnaMartinkova.modal.lateClose()
    }, 600)
  }

};


var modalWindowConditions = new ModalWindow(document.getElementById('modal-conditions'));
var modalWindowConditionsAnimationMain = new TweenMax.fromTo(modalWindowConditions.container, .3, {opacity: 0}, {opacity: 1}).pause();
var modalWindowConditionsAnimationContent = new TweenMax.fromTo('.modal-conditions__animation-content', .6, {y: -500}, {y: 0}).pause();

modalWindowConditions.afterOpen = (switchWindow) => {
  modalWindowConditions.container.scrollTop = 0;
  modalWindowConditionsAnimationMain.play();
  modalWindowConditionsAnimationContent.play();
};

modalWindowConditions.afterClose = (switchWindow) => {
  modalWindowConditionsAnimationMain.reverse();
  modalWindowConditionsAnimationContent.reverse();

  if (!switchWindow) {
    setTimeout(() => {
      modalWindowConditions.modal.lateClose()
    }, 600)
  }
};

modal.addWindow('martinka', modalWindowMartinka);
modal.addWindow('janfischer', modalWindowJanFsicher);
modal.addWindow('adamdanielkraus', modalWindowAdamDanielKraus);
modal.addWindow('nikolaaichingerova', modalWindowNikolaAichingerova);
modal.addWindow('timonsvoboda', modalWindowTimonSvoboda);
modal.addWindow('annahavrankova', modalWindowAnnaHavrankova);
modal.addWindow('veronikaotahalova', modalWindowVeronikaOtahalova);
modal.addWindow('tereziepistkova', modalWindowTereziePistkova);
modal.addWindow('annamartinkova', modalWindowAnnaMartinkova);
modal.addWindow('colleagues', modalWindowColleagues);
modal.addWindow('conditions', modalWindowConditions);


/******************************************
 * Services more Link
 ******************************************/

var collapseButton = document.querySelector('.collapse__link');
var collapseContent = document.getElementById('services-collapse');
var collapsed = false;

var showCollapse = function () {
  if (collapsed) return;
  collapsed = true;

  collapseButton.classList.add('collapsed');

  let clone = collapseContent.cloneNode(true);

  clone.style.visibility = 'hidden';
  clone.style.display = 'flex';
  clone.style.position = 'absolute';
  clone.style.bottom = 0;
  clone.style.zIndex = '-99999';

  document.body.appendChild(clone);
  let height = parseInt(clone.clientHeight);
  document.body.removeChild(clone);

  collapseContent.style.display = 'flex';
  collapseContent.classList.add('collapsing');
  setTimeout(() => {
    collapseContent.style.height = height + 'px';
  }, 50);

  setTimeout(() => {
    collapseContent.classList.remove('collapsing');
    collapseContent.style.height = '';
  }, 400)
};

var hideCollapse = function () {

  console.log(0)

  if (!collapsed) return;
  collapsed = false;

  collapseButton.classList.remove('collapsed');
  collapseContent.setAttribute('style', '');

  if (window.outerWidth < 769) {
    setTimeout(function () {
      zenscroll.center(servicesContainer)
    }, 100)
  }
};

if (collapseButton) {
  collapseButton.addEventListener('click', () => {
    if (collapsed) hideCollapse();
    else showCollapse();
  })
}

/******************************************
 * events
 ******************************************/

var resizeTimeout;
window.addEventListener('resize', () => {
  clearTimeout(resizeTimeout);
  resizeTimeout = setTimeout(() => {
    triangleContainer.style.height = window.innerHeight + aboutContainer.offsetHeight + 'px';
  }, 50)
});

window.addEventListener('keydown', (e) => {
  if (e.key === 'Escape' && modal.isOpen) modal.close()
});


